import { Injectable } from '@angular/core';
import { Materials, ShortMaterials } from '@app/models/materialItems';
import { ExportToCsv } from 'export-to-csv';
import { TicketStatus } from '@app/models/ticketStatus.enum';
import { ReceptionStatus } from '@app/models/reception';
import { ActivityStatus, ActivityType } from '@app/models/activity';
import BigNumber from 'bignumber.js';
import { DocumentMaterialMovements } from '@app/models/oc';
import Axios from 'axios';
import { format, parseISO } from 'date-fns';
import {
  ActivityCategoryType,
  BudgetGroupType,
  WorkPackageType,
  SpecialtyContractStatus,
  NoticeLetterStatus,
  MaterialStatus,
  GuaranteeDocumentStatus,
  GuaranteeDocumentArea,
} from '@app/models';
import { ProviderLegalType, ProviderStatus } from '@app/models/provider';
import { PaymentStatusType } from '@app/models/paymentStatus';

@Injectable({
  providedIn: 'root',
})
export class FunctionService {
  collator = new Intl.Collator('es', { numeric: true });
  constructor() {}
  /**
   * As a given state returns user readable state
   * @param state Db state
   */
  processState(state: string): string {
    switch (TicketStatus[state]) {
      case TicketStatus.PENDING_APPROVAL:
        return 'POR APROBAR';
      case TicketStatus.REJECTED:
        return 'RECHAZADO';
      case TicketStatus.WAITING_PICKUP:
        return 'APROBADO';
      case TicketStatus.DELIVERED:
        return 'ENTREGADO';
      case TicketStatus.EXPIRED:
        return 'EXPIRADO';
      case TicketStatus.IN_PREPARATION:
        return 'PREPARANDO';
      default: {
        return 'POR APROBAR';
      }
    }
  }
  processReceptionStatus(state: string): string {
    switch (state) {
      case ReceptionStatus.PENDING:
        return 'POR APROBAR';
      case ReceptionStatus.ACCEPTED:
        return 'APROBADA';
      case ReceptionStatus.REJECTED:
        return 'RECHAZADA';
      case ReceptionStatus.PAID:
        return 'PAGADA';
      default: {
        return '';
      }
    }
  }
  /**
   * As a given state returns user readable state
   * @param state Db state
   */
  prettyProcessState(state: string): string {
    switch (TicketStatus[state]) {
      case TicketStatus.PENDING_APPROVAL:
        return 'esperando aprobación 🚨';
      case TicketStatus.REJECTED:
        return 'rechazado 🚨';
      case TicketStatus.WAITING_PICKUP:
        return 'aprobado 🆗';
      case TicketStatus.DELIVERED:
        return 'entregado 🆗';
      case TicketStatus.EXPIRED:
        return 'expirado 🚨';
      default:
        return 'esperando aprobación 🚨';
    }
  }

  /**
   * As a given ActivityType returns user readable type
   * @param type ActivityType to convert
   */
  processActivityType(type: ActivityType): string {
    switch (type) {
      case ActivityType.house:
        return 'Vivienda';
      case ActivityType.building:
        return 'Edificio';
      case ActivityType.urba:
        return 'Urbanización';
      case ActivityType.other:
        return 'Habilitación y Otros';
      case ActivityType.house_repair:
        return 'Vivienda Reparación';
      case ActivityType.urba_repair:
        return 'Urbanización Reparación';
      case ActivityType.building_repair:
        return 'Edificio Reparación';
      case ActivityType.other_repair:
        return 'Habilitación y Otros Reparación';
      default:
        return '';
    }
  }
  processBudgetGroupType(type: BudgetGroupType): string {
    switch (type) {
      case BudgetGroupType.HOUSE:
        return 'Vivienda';
      case BudgetGroupType.HOUSE_REPAIR:
        return 'Vivienda Reparación';
      case BudgetGroupType.URBANIZATION:
        return 'Urbanización';
      case BudgetGroupType.URBANIZATION_REPAIR:
        return 'Urbanización Reparación';
      case BudgetGroupType.BUILDING:
        return 'Edificio';
      case BudgetGroupType.BUILDING_REPAIR:
        return 'Edificio Reparación';
      case BudgetGroupType.OTHER:
        return 'Habilitación y Otros';
      case BudgetGroupType.OTHER_REPAIR:
        return 'Habilitación y Otros Reparación';
      default:
        return '';
    }
  }
  processActivityCategoryType(type: ActivityCategoryType): string {
    switch (type) {
      case ActivityCategoryType.HOUSE:
        return 'Vivienda';
      case ActivityCategoryType.HOUSE_REPAIR:
        return 'Vivienda Reparación';
      case ActivityCategoryType.URBANIZATION:
        return 'Urbanización';
      case ActivityCategoryType.URBANIZATION_REPAIR:
        return 'Urbanización Reparación';
      case ActivityCategoryType.BUILDING:
        return 'Edificio';
      case ActivityCategoryType.BUILDING_REPAIR:
        return 'Edificio Reparación';
      case ActivityCategoryType.OTHER:
        return 'Habilitación y Otros';
      case ActivityCategoryType.OTHER_REPAIR:
        return 'Habilitación y Otros Reparación';
      case ActivityCategoryType.SERVICE:
        return 'Servicio';
      case ActivityCategoryType.LABOR:
        return 'Adicionales/Descuentos EP';
      default:
        return '';
    }
  }
  processSpecialtyContractStatus(status: SpecialtyContractStatus): string {
    switch (status) {
      case SpecialtyContractStatus.DRAFT:
        return 'Borrador';
      case SpecialtyContractStatus.IN_REVIEW:
        return 'En Revisión';
      case SpecialtyContractStatus.PENDING:
        return 'Pendiente Firma';
      case SpecialtyContractStatus.SIGNED:
        return 'Activo';
      case SpecialtyContractStatus.CLOSED:
        return 'Expirado';
      case SpecialtyContractStatus.REJECTED:
        return 'Rechazado';
      default:
        return '';
    }
  }
  processWorkPackageType(type: WorkPackageType): string {
    switch (type) {
      case WorkPackageType.HOUSE:
        return 'Vivienda';
      case WorkPackageType.BUILDING:
        return 'Edificio';
      case WorkPackageType.URBANIZATION:
        return 'Urbanización';
      case WorkPackageType.OTHER:
        return 'Habilitación y Otros';
      case WorkPackageType.HOUSE_REPAIR:
        return 'Vivienda Reparación';
      case WorkPackageType.URBANIZATION_REPAIR:
        return 'Urbanización Reparación';
      case WorkPackageType.BUILDING_REPAIR:
        return 'Edificio Reparación';
      case WorkPackageType.OTHER_REPAIR:
        return 'Habilitación y Otros Reparación';
      case WorkPackageType.SERVICE:
        return 'Servicio';
      case WorkPackageType.LABOR:
        return 'Adicionales/Descuentos EP';
      default:
        return '';
    }
  }
  processProviderLegalType(legalType: ProviderLegalType): string {
    switch (legalType) {
      case ProviderLegalType.NATURAL:
        return 'Persona natural';
      case ProviderLegalType.LEGAL:
        return 'Persona jurídica constituida bajo escritura pública';
      case ProviderLegalType.LEGAL_ONE_DAY:
        return 'Persona jurídica constituida en un día';
      case ProviderLegalType.SERVICE:
        return 'Contratista de servicio';
      default:
        return '';
    }
  }
  processNoticeLetterStatus(noticeLetterStatus: NoticeLetterStatus): string {
    switch (noticeLetterStatus) {
      case NoticeLetterStatus.PENDING:
        return 'Pendiente Firma';
      case NoticeLetterStatus.SIGNED:
        return 'Firmada';
      case NoticeLetterStatus.REJECTED:
        return 'Rechazada';
      case NoticeLetterStatus.SENT:
        return 'Enviada';
      default:
        return '';
    }
  }
  processMaterialStatus(status: MaterialStatus, enabled: boolean): string {
    switch (true) {
      case status === MaterialStatus.DRAFT:
        return 'Borrador';
      case status === MaterialStatus.PENDING_CORRECTION:
        return 'Por Corregir';
      case status === MaterialStatus.PENDING:
        return 'Por Validar';
      case status === MaterialStatus.ACTIVE && enabled:
        return 'Habilitado';
      case status === MaterialStatus.ACTIVE && !enabled:
        return 'Deshabilitado';
      default:
        return '';
    }
  }
  processProviderStatus(status: ProviderStatus, enabled: boolean): string {
    switch (true) {
      case status === ProviderStatus.DRAFT:
        return 'Borrador';
      case status === ProviderStatus.PENDING_MATERIAL_SERVICE:
      case status === ProviderStatus.PENDING_CONTRACTOR:
        return 'Por Validar';
      case status === ProviderStatus.ACTIVE && enabled:
        return 'Habilitado';
      case status === ProviderStatus.ACTIVE && !enabled:
        return 'Deshabilitado';
      default:
        return '';
    }
  }
  processActivityStatus(status: ActivityStatus, enabled: boolean): string {
    switch (true) {
      case status === ActivityStatus.DRAFT:
        return 'Borrador';
      case status === ActivityStatus.PENDING:
        return 'Por Validar';
      case status === ActivityStatus.ACTIVE && enabled:
        return 'Habilitada';
      case status === ActivityStatus.ACTIVE && !enabled:
        return 'Deshabilitada';
      default:
        return '';
    }
  }
  processPaymentStatusType(type: PaymentStatusType): string {
    switch (type) {
      case PaymentStatusType.LEGACY:
        return 'Antiguo';
      case PaymentStatusType.PROGRESS:
        return 'Avance';
      case PaymentStatusType.ADDITIONAL:
        return 'Adicionales';
      case PaymentStatusType.RETENTION:
        return 'Retenciones';
      default:
        return '';
    }
  }
  processGuaranteeDocumentStatus(status: GuaranteeDocumentStatus): string {
    switch (status) {
      case GuaranteeDocumentStatus.DRAFT:
        return 'Borrador';
      case GuaranteeDocumentStatus.PENDING_REVIEW:
        return 'Por Aprobar';
      case GuaranteeDocumentStatus.IN_ISSUE:
        return 'En Emisión';
      case GuaranteeDocumentStatus.CURRENT:
        return 'Vigente';
      case GuaranteeDocumentStatus.PENDING_COLLECTION:
        return 'En Cobro';
      case GuaranteeDocumentStatus.COLLECTED:
        return 'Cobrada';
      case GuaranteeDocumentStatus.EXPIRED:
        return 'Expirada';
      case GuaranteeDocumentStatus.REJECTED:
        return 'Rechazada';
      case GuaranteeDocumentStatus.DESISTED:
        return 'Desistida';
      default:
        return '';
    }
  }

  processGuaranteeDocumentArea(status: GuaranteeDocumentArea): string {
    switch (status) {
      case GuaranteeDocumentArea.PRODUCTION:
        return 'Producción';
      case GuaranteeDocumentArea.PROJECT:
        return 'Proyectos';
      default:
        return '';
    }
  }

  /**
   * As a given sentence returns the first letter c
   * @param sentence string to convert
   */
  capitalizeOnlyFirst(sentence: string): string {
    return sentence.charAt(0).toUpperCase() + sentence.substring(1).toLowerCase();
  }
  /**
   * Generates summary ticket message, whatsapp ready text
   * @param issuedDate Issued Date
   * @param businessName Business Name
   * @param picker Picker Name
   * @param activitySelectedName Activity Name
   * @param subsidiary Subsidiary Name
   * @param project Project Name
   * @param stageSelectedName SelectedStage Name
   * @param lotsSelected Lots
   * @param materials Ticket materials
   * @param reason Warning reasons
   */
  whatsappSummary(
    issuedDate: Date,
    businessName: string,
    picker: string,
    activitySelectedName: string,
    subsidiary: string,
    project: string,
    stageSelectedName: string,
    lotsSelected: string,
    materials: Materials[] | ShortMaterials[],
    reason?: string,
  ): string {
    const warning = reason ? `%0A%E2%9A%A0%EF%B8%8F%20${reason}` : '';

    // eslint-disable-next-line max-len
    const header = `%2AResumen%20Pedido%2A%0A%0A%F0%9F%91%A4%20${businessName}%0A%F0%9F%91%A5%20${picker}%0A%F0%9F%9B%A0%20${this.capitalizeOnlyFirst(
      activitySelectedName,
    )}%0A%F0%9F%93%8D%20${stageSelectedName}%20%2F%20${lotsSelected}%0A%F0%9F%93%86%20${issuedDate.getDate()}/${
      issuedDate.getMonth() + 1
    }/${issuedDate.getFullYear()}
      ${warning}`;
    const materialTemplate = ({ name, quantity, unit }) =>
      `%E2%9C%85%20${this.capitalizeOnlyFirst(name)}%20%28${unit}%29%3A%20%2A${this.roundUp(quantity)}%2A%0A`;
    const mappedMaterials = materials
      .filter(({ quantity }) => quantity > 0)
      .map(({ name, quantity, unit }) =>
        materialTemplate({
          name,
          quantity,
          unit,
        }),
      )
      .join('');
    const materialBody = `%0A%0A%2AMateriales%2A%20%20%20%20%20%20%20%20%0A%0A${mappedMaterials}`;
    return `${header}${materialBody}`;
  }

  /**
   * Returns readable(rounded) number
   * @param num Number to be rounded
   * @param precisionQuantity Number of digits in the result
   */
  roundUp(num: number, precisionQuantity = 1): number {
    const precision = 10 ** precisionQuantity;
    return Math.round(num * precision) / precision;
  }
  /**
   * Returns clickable share wsp link
   * @param id Ticket id
   * @param status Ticket Status
   * @param shareMessage Message to sent
   */
  getLinkTicketWsp(id: string, status: string, shareMessage: string): string {
    // eslint-disable-next-line max-len
    return `https://api.whatsapp.com/send?text=Vale%20n%C2%BA%20${id}%20est%C3%A1%20%2A${status}%2A%0A%0A${shareMessage}`;
  }
  /**
   * Calculate received material in a purchase order
   * @param documents All document material movements
   * @param materialID The material id
   */
  calculateReceivedMaterial(documents: DocumentMaterialMovements[], materialID: number): number {
    return documents
      .map((d) => d.details)
      .reduce((t, a) => [...t, ...a], [])
      .filter((d) => d.material.id === materialID)
      .reduce((total, actual) => {
        const tot = new BigNumber(total);
        const quantity = new BigNumber(actual.quantityConverted);
        const result = tot.plus(quantity).toNumber();
        return result;
      }, 0);
  }

  // allow only numbers on input keypress
  numberOnly(event): boolean {
    const regOnlyDigit = new RegExp('^[0-9]$');
    return regOnlyDigit.test(event.key);
  }

  // Define material quantity increment/decrement interval;
  setValueInterval(unit: string): number {
    if (unit === 'un') {
      return 1;
    }
    return 0.5;
  }

  exportCsv(exportData, filename: string): void {
    const csvOptions = {
      filename: filename,
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalSeparator: ',',
      showLabels: false,
      showTitle: false,
      title: filename,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(exportData);
  }

  calculateNumberInputDelta(oldValue: number, newValue: number, defaultIncrement: number, wasButton: boolean): number {
    let delta = newValue - oldValue;
    const increasing = delta > 0;
    if (wasButton) {
      const remainder = oldValue % defaultIncrement;
      if (Math.abs(remainder) > 0.05 && Math.abs(remainder - defaultIncrement) > 0.05) {
        delta = increasing ? defaultIncrement - remainder : -remainder;
      }
    }
    return delta;
  }
  async getUFSpecificDate(date: Date) {
    const res = await Axios.get<{ serie: { valor: number }[] }>(
      `https://mindicador.cl/api/uf/${format(date, 'dd-MM-yyyy')}`,
    );
    return res.data;
  }

  compare(a: string | number, b: string | number, isAsc: boolean): number {
    if (typeof a === 'number' || typeof b === 'number') {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    } else {
      return this.collator.compare(a, b) * (isAsc ? 1 : -1);
    }
  }

  nullCompare(a: string | number, b: string | number, isAsc?: boolean): number {
    if (!a && a !== 0) return 1;
    if (!b && b !== 0) return -1;
    if (!a && a !== 0 && !b && b !== 0) return 0;
    return this.compare(a, b, isAsc);
  }

  fromDateToMs(date: Date | string): number {
    return parseISO(String(date)).getTime();
  }

  static joinStringList(data: string[]): string {
    if (data.length === 0) return '';
    else if (data.length === 1) return data[0];
    const lastEntry = data[data.length - 1];
    return [data.slice(0, data.length - 1).join(', '), lastEntry].join(' y ');
  }

  capitalizeWordsFirstLetter(sentence: string): string {
    return (
      sentence
        ?.split(' ')
        .map((word) => word.trim())
        .map(this.capitalizeOnlyFirst)
        .join(' ') ?? ''
    );
  }
  stringCompare(a: string, b: string, caseSensitive = false) {
    if (caseSensitive) return a === b;
    return a?.toLocaleLowerCase().localeCompare(b?.toLocaleLowerCase()) == 0;
  }
}
