import { BaseAttributes } from './_base';
import { Bank } from './bank';
import { Branch } from './branch';
import { Provider } from './provider';
import { User } from './user';

// eslint-disable-next-line no-shadow
export enum GuaranteeDocumentStatus {
  DRAFT = 'DRAFT',
  PENDING_REVIEW = 'PENDING_REVIEW',
  IN_ISSUE = 'IN_ISSUE',
  CURRENT = 'CURRENT',
  PENDING_COLLECTION = 'PENDING_COLLECTION',
  COLLECTED = 'COLLECTED',
  EXPIRED = 'EXPIRED',
  REJECTED = 'REJECTED',
  DESISTED = 'DESISTED',
}

// eslint-disable-next-line no-shadow
export enum GuaranteeDocumentArea {
  PROJECT = 'PROJECT',
  PRODUCTION = 'PRODUCTION',
}

export interface GuaranteeDocument extends BaseAttributes {
  id: number;
  branch: Branch;
  status: GuaranteeDocumentStatus;
  area: GuaranteeDocumentArea;
  category: string;
  provider: Provider;
  contactName: string;
  contactPhoneNumber: string;
  contactEmail: string;
  backupAttachment: string;
  startDate: string;
  endDate: string;
  currency: string;
  amount: number;
  paymentType: string;
  recoverBy: User;
  bank: Bank;
  rate: number;
  requestDocumentAttachment: string;
  documentNumber: string;
  documentProofAttachment: string;
  justification: string;
  description: string;
  comments: string;
  paymentRequestAttachment: string;
  withdrawalAttachment: string;
  requestedBy: User;
  recovered: boolean;
  previousGuaranteeDocument: GuaranteeDocument;
}
